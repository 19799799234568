/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

/**
 * @param {string} key
 * @returns {string|undefined}
 */
export default function getGtmVariable(key) {
  const value = window[key];
  if (['undefined', undefined].includes(value)) return undefined;
  if (['true', 'false'].includes(value)) return JSON.parse(value);
  return value;
}
