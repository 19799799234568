/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

const checkEvtIdIsValid = id => id.length === 24;

/**
 * @typedef GetQueryParamOptions
 * @property {boolean} isEvtId
 * @property {boolean} isRequired
 */

/**
 * @param {string} key
 * @param {GetQueryParamOptions} options
 * @returns {string|undefined}
 */
export function getQueryParam(key, options = {}) {
  const { isEvtId, isRequired } = options;

  const queryParams = new URLSearchParams(window.location.search);

  const value = queryParams.get(key) ?? undefined;

  if (isRequired && !value) {
    throw new Error(
      `URL param value for ${key} is required. Received '${value}'.`,
    );
  }

  if (isEvtId && value && !checkEvtIdIsValid(value)) {
    throw new Error(
      `URL param value for ${key} is invalid EVRYTHNG id. Received '${value}'.`,
    );
  }

  return value;
}

export function setQueryParam(key, value) {
  const newUrl = new URL(window.location);
  newUrl.searchParams.set(key, value);
  // Change query params without causing reload
  window.history.replaceState({}, '', newUrl);
}
