/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import { v4 as uuid } from 'uuid';
import { totalTimeTraceTransaction } from './sentry';
import {
  COOKIE_KEYS,
  QUERY_PARAM_KEYS,
  GTM_VAR_KEYS,
  LOG_MESSAGES,
} from './constants';
import getGtmVariable from './getGtmVariable';
import { getQueryParam, setQueryParam } from './queryParam';
import { getCookie, removeCookie, setCookie } from './cookiesUtils';
import createSdk from './evrythng';

export default async function performCompletedScan() {
  const product = getQueryParam(QUERY_PARAM_KEYS.PRODUCT_ID, { isEvtId: true });
  if (!product) {
    console.log(LOG_MESSAGES.MISSING_PRODUCT);
    return;
  }

  const areCookiesBlocked = getGtmVariable(GTM_VAR_KEYS.ARE_COOKIES_BLOCKED);

  if (areCookiesBlocked) {
    removeCookie(COOKIE_KEYS.IMPLICIT_SCAN_ID);
    removeCookie(COOKIE_KEYS.CONSUMER_ID);
    console.log(LOG_MESSAGES.COOKIES_ARE_BLOCKED);
    return;
  }

  const implicitScanId = getQueryParam(QUERY_PARAM_KEYS.IMPLICIT_SCAN_ID, {
    isEvtId: true,
  });

  const previousImplicitScanId = getCookie(COOKIE_KEYS.IMPLICIT_SCAN_ID);

  const isRedundantScan =
    implicitScanId &&
    previousImplicitScanId &&
    implicitScanId === previousImplicitScanId;

  if (isRedundantScan) {
    console.log(LOG_MESSAGES.REDUNDANT_SCAN);
    return;
  }

  const existingConsumerId = getCookie(COOKIE_KEYS.CONSUMER_ID);
  // If pre-existing cookie, set again to extend expiry
  const consumerId = setCookie(
    COOKIE_KEYS.CONSUMER_ID,
    existingConsumerId ?? uuid(),
  );

  const accessToken =
    getGtmVariable(GTM_VAR_KEYS.ACCESS_TOKEN) ??
    getQueryParam(QUERY_PARAM_KEYS.ACCESS_TOKEN, { isRequired: true });

  const region = getGtmVariable(GTM_VAR_KEYS.REGION);

  const thng = getQueryParam(QUERY_PARAM_KEYS.THNG_ID, { isEvtId: true });

  const { createAction } = createSdk(accessToken, region);

  // Completed Scan previously created
  // Indicates visit is via shared URL instead of QR code scan-generated URL
  const isScanCreated = !!getQueryParam(QUERY_PARAM_KEYS.SCAN_CREATED);

  const actionType = isScanCreated ? 'referrals' : 'scans';

  const createdAction = await createAction({
    actionType,
    consumerId,
    parentActionId: implicitScanId,
    product,
    thng,
  });

  // Cause shared URL to have query param indicating so
  setQueryParam(QUERY_PARAM_KEYS.SCAN_CREATED, Date.now());

  if (implicitScanId) setCookie(COOKIE_KEYS.IMPLICIT_SCAN_ID, implicitScanId);

  console.log(
    actionType === 'referrals'
      ? LOG_MESSAGES.REFERRAL_ACTION
      : LOG_MESSAGES.SCAN_COMPLETED,
  );

  // send transaction to sentry before redirect
  totalTimeTraceTransaction.finish();

  const { redirectUrl, redirectionContext } =
    createdAction?.reactions?.[0] || {};
  const { contextScriptRedirection } = redirectionContext?.constants || {};

  if (redirectUrl && contextScriptRedirection) {
    console.log(`${LOG_MESSAGES.REDIRECTING} ${redirectUrl}`);
    window.location.replace(redirectUrl);
  }
}
