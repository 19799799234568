/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import {
  init as sentryInit,
  startTransaction,
  captureException,
} from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { name as scriptName, version as scriptVersion } from '../package.json';

sentryInit({
  enabled:
    process.env.NODE_ENV === 'production' &&
    !['alpha', 'beta'].includes(scriptVersion),
  dsn: 'https://3a607b104b05456596f4a2c6390983b6@o18925.ingest.sentry.io/5681029',
  environment: window.location.host,
  // sentry doesn't allow '/' in release, so removing namespace
  release: `${scriptName.split('/')[1]}@${scriptVersion}`,
  integrations: [
    new Integrations.BrowserTracing({
      // disable automatic trace since script runs within web page usually not controlled by EVT
      startTransactionOnLocationChange: false,
      startTransactionOnPageLoad: false,
    }),
  ],
  // disable automatic logs since script runs within web page usually not controlled by EVT
  defaultIntegrations: false,
  autoSessionTracking: false,
  ignoreErrors: ['Authorization error.'],
  tracesSampleRate: 0.1,
  sampleRate: 0.1,
});

export const totalTimeTraceTransaction = startTransaction({
  name: `total-time`,
  data: { url: window.location.href },
});

export { startTransaction, captureException };
