/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

export default function extractErrorMessage(err) {
  try {
    return JSON.parse(err?.message).errors[0];
  } catch {
    return err?.message || err?.toString() || 'Unknown error';
  }
}
