/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import { captureException } from './sentry';
import performCompletedScan from './performCompletedScan';
import extractErrorMessage from './extractErrorMessage';
import { LOG_MESSAGES } from './constants';

console.log(LOG_MESSAGES.SCRIPT_VERSION);
performCompletedScan().catch(async err => {
  if (process.env.NODE_ENV !== 'production') throw err;

  // fetch errors are rejected Promises
  const error = err instanceof Promise ? await err?.catch(res => res) : err;
  const message = extractErrorMessage(error);

  console.error(`${LOG_MESSAGES.SCAN_ERROR}: ${message}`);
  captureException(error);
});
