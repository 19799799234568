/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import Cookies from 'js-cookie';

export const YEAR_IN_DAYS = 365;

export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookie(key, value) {
  Cookies.set(key, value, { expires: YEAR_IN_DAYS });
  return value;
}

export function removeCookie(key) {
  Cookies.remove(key);
}
