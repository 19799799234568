/*
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */
import { name as scriptName, version as scriptVersion } from '../package.json';

export const COOKIE_KEYS = {
  IMPLICIT_SCAN_ID: 'EVT_IMPLICIT_SCAN_ID',
  CONSUMER_ID: 'EVT_CONSUMER_ID',
};

export const QUERY_PARAM_KEYS = {
  ACCESS_TOKEN: 'evt_token',
  IMPLICIT_SCAN_ID: 'evt_implicit_scan_id',
  PRODUCT_ID: 'evt_product_id',
  THNG_ID: 'evt_thng_id',
  SCAN_CREATED: 'evt_scan_created',
};

export const GTM_VAR_KEYS = {
  ARE_COOKIES_BLOCKED: 'GTM_EVT_ARE_COOKIES_BLOCKED',
  ACCESS_TOKEN: 'GTM_EVT_TOKEN',
  REGION: 'GTM_EVT_REGION',
};

export const LOG_MESSAGES = {
  SCRIPT_VERSION: `${scriptName}: v${scriptVersion}`,
  MISSING_PRODUCT: `${scriptName}: QR code scan not performed due to missing '${QUERY_PARAM_KEYS.PRODUCT_ID}'`,
  COOKIES_ARE_BLOCKED: `${scriptName}: QR code scan not performed due to blocked cookies.`,
  REDUNDANT_SCAN: `${scriptName}: Consumer "completed" scan was already created for this "implicit" scan. Exiting.`,
  REFERRAL_ACTION: `${scriptName}: Visiting from a shared link, creating referral Action.`,
  SCAN_COMPLETED: `${scriptName}: QR code scan complete.`,
  SCAN_ERROR: `${scriptName}: Error completing QR code scan:`,
  REDIRECTING: `${scriptName}: Redirecting to URL:`,
};
